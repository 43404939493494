// import logo from './logo.svg';
import logo from './monument.svg';
import './App.css';
import ApiDataDisplay from './ApiDataDisplay';
import { useEffect } from 'react';
import Subscribe from './Subscribe';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Service Monitor
        </h1>
      </header>
      <ApiDataDisplay />
      <Subscribe />
    </div>
  );
}

export default App;
