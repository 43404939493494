
import React, { useState, useEffect } from 'react';
import './ApiDataDisplay.css';

function ApiDataDisplay() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://manager.steer.space/vps_status');
                const data = await response.json();

                const sortedData = data.sort((a, b) => {
                    const dateA = new Date(a.last_update_time);
                    const dateB = new Date(b.last_update_time);
                    return dateB - dateA;
                });
                setData(sortedData);
            }
            catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const isOverOneDay = (timestampString) => {
        const timestamp = new Date(timestampString.replace(/-/g, '/')); // 将字符串中的横线替换为斜杠
        const lastUpdateDate = new Date(timestamp);
        const currentDate = new Date();
        const timeDifference = currentDate - lastUpdateDate;
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 一天的毫秒数

        return timeDifference > oneDayInMilliseconds;
    }
    const getMtrUrl = (base_domain) => {
        return "https://ping.pe/" + base_domain
    }
    const getPingUrl = (base_domain) => {
        let ret = "https://ping.sx/ping?t=" + base_domain
        // console.log(ret)
        return ret
    }

    const getNetTraffic = (rx, tx) => {
        return <div className='new-line'>{rx + '↓' + '\n' + tx + '↑'}</div>

    }
    const mergeOneLine = (front, back) => {
        return <div className='new-line'>{front + ' \n ' + back}</div>
    }

    const splitStringAtNthChar = (str, char, nth) => {
        let strim_str = str.trim();
        let idx = 0;
        let count = 0;
        for (let i = 0; i < strim_str.length; i++) {
            if (strim_str[i] === char) {
                count++;
                if (count === nth) {
                    idx = i;
                    break;
                }
            }
        }
        let ret = [strim_str.slice(0, idx), strim_str.slice(idx + 1)];
        // console.log("splitStringAtNthChar", ret, strim_str, char, nth)
        return ret;
    }

    const getProgress = (used, total) => {
        let regex = /[+-]?\d+(\.\d+)?/g;
        let used_val = used.match(regex).map(function (v) { return parseFloat(v); });
        let total_val = total.match(regex).map(function (v) { return parseFloat(v); });
        let raw_percent = used_val / total_val * 100
        let percent = Math.floor(raw_percent / 20) + 1;
        let ret = total + '\n'
        for (let index = 0; index < 5; index++) {
            if (index < percent) {
                ret += '●'
            } else {
                ret += '○'
            }

        }
        ret += '🅥' + Math.round(raw_percent) + '％'
        return <div className='new-line'>{ret}</div>
    }
    return (
        <div>
            {
                data.length === 0 && <p>
                    Waiting for data...
                </p>
            }
            {
                data.length > 0 && (
                    <table>
                        <thead>

                            <tr>
                                <th>Loc</th>
                                <th>Domain</th>
                                <th>Organization</th>
                                <th>Update Time</th>
                                <th>Uptime</th>
                                <th>Mem</th>
                                <th>Traffic</th>

                                <th>🐶Net</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>{mergeOneLine(item.country, item.city)}</td>
                                    <td style={isOverOneDay(item.last_update_time) ? { textDecoration: 'line-through' } : {}}>
                                        <a href={'https://' + item.domain} target="_blank" rel="noreferrer">
                                            {item.domain}
                                        </a>
                                    </td>
                                    <td>

                                        {mergeOneLine(...splitStringAtNthChar(item.org, ' ', 1))}
                                    </td>
                                    <td>{mergeOneLine(...item.last_update_time.split(','))}</td>
                                    <td>{
                                        mergeOneLine(...splitStringAtNthChar(item.uptime, ' ', 2))
                                    }</td>
                                    <td>{getProgress(item.memory_used, item.memory_total)}</td>

                                    <td>{getNetTraffic(item.network_in, item.network_out)}</td>

                                    <td>
                                        <a href={getMtrUrl(item.domain)} target="_blank" rel="noreferrer">
                                            MTR&Ping
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            }

        </div>
    );
}

export default ApiDataDisplay;
