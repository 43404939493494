import React from "react";
import "./Subscribe.css";

function Subscribe() {
    const handleClick = async () => {
        let password = prompt("PassWord");
        // send password to backend and open returned url
        // if return {"status": "error", "message": "wrong password"} then alert("wrong password")
        // if return {"status": "success", "url": "https://example.com"} then window.open("https://example.com")
        try {
            let resp = await fetch("/get_subscribe_url", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ password: password })
            });
            const data = await resp.json();
            if (data.status === "error") {
                alert(data.msg);
            }
            else if (data.status === "success") {
                window.open(data.url);
            }
            else {
                alert("YOU SHALL NOT PASS!");
            }
        }
        catch (error) {
            console.log(error);
            alert("YOU SHALL NOT PASS!");
        }

    }
    return <div className="subscribe_foot" onClick={handleClick}>
        Subscribe
    </div>;
}

export default Subscribe;